import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import profilepicture from "../assets/profilepicture.jpg";
import GlobalFonts from "../fonts/fonts";

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  place-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
  color: #333333;
  font-size: 40px;
`;

const AboutContainer = styled.div`
  padding: 15px 0 0 0;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ProfilePicture = styled.img`
  display: flex;
  height: 350.25px;
  width: 300px;

  @media (max-width: 768px) {
    height: 233.5px;
    width: 200px;
  }
`;

const AboutMe = styled.p`
  font-family: "Mark Pro" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  width: 50vw;
  color: #333333;
  display: flex;
  align-items: center;
  text-align: justify;

  @media (max-width: 768px) {
    padding: 15px 0 0 0;
    width: 80%;
  }
`;

const About = () => {
  return (
    <div id="apropos">
      <GlobalFonts />
      <ContainerTitle>
        <FontAwesomeIcon icon="user" style={{ marginRight: "15px" }} />à propos
      </ContainerTitle>
      <AboutContainer>
        <ProfilePicture src={profilepicture}></ProfilePicture>
        <AboutMe>
          Développeur web junior curieux, autonome, j'aime les applications
          rapides et efficaces.
          <br />
          <br />
          Un sens de l'écoute renforcé par les différents projets
          intra-scolaires que j'ai pu mener en équipe. Une autonomie et
          proactivité développée lors de mes précédentes expériences
          professionnelles. Une expertise en constante évolution grâce à la
          perpétuelle veille technologique que je m'oblige de respecter.
          Toujours plus motivé à rendre une application la plus fidèle possible,
          je m'épanouis dans le développement de ces dernières.
          <br />
          <br />
          Je suis actuellement à la recherche d'un poste de développeur web à
          plein temps, je suis à votre disposition.
        </AboutMe>
      </AboutContainer>
    </div>
  );
};

export default About;
