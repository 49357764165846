import React from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import About from "./components/About";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Diplomas from "./components/Diplomas";
import Hobbies from "./components/Hobbies";
import "./components/FontAwesomeIcons";
import { createGlobalStyle } from "styled-components";
import Footer from "./components/Footer";
import { StickyContainer, Sticky } from "react-sticky";

const GlobalStyle = createGlobalStyle`
    html {
      background-color: #f2f2f2;
      scroll-behavior: smooth;
    }

    body {
      overflow-x: hidden;
    }
`;

function App() {
  return (
    <div className="App">
      <link
        href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&family=Roboto&display=swap"
        rel="stylesheet"
      ></link>
      <GlobalStyle />
      <Header />
      <StickyContainer>
        <Sticky disableHardwareAcceleration>
          {({ style }) => (
            <div style={style}>
              <NavBar />
            </div>
          )}
        </Sticky>
        <About />
        <Skills />
        <Experience />
        <Diplomas />
        <Hobbies />
        <Footer />
      </StickyContainer>
    </div>
  );
}

export default App;
