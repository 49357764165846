import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createGlobalStyle, keyframes } from "styled-components";
import styled from "styled-components";
import background from "../assets/background.jpg";
import headerprofilepicture from "../assets/headerprofilepicture.png";

const GlobalStyle = createGlobalStyle`
    html {
    }

    body {
    }
`;

const BackgroundImg = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(rgba(96, 163, 188, 0.6), rgba(130, 204, 221, 0.6)),
    url(${background}) no-repeat center fixed;
  -webkit-background-size: cover; /* pour anciens Chrome et Safari */
  background-size: cover; /* version standardisée */
  background-position: center center;
  /*box-sizing: border-box !important;*/
`;

const HeaderProfilePicture = styled.div`
  display: grid;
  place-items: center;
  padding-top: 15vh;
`;

const Name = styled.h1`
  text-align: center;
  font-family: Barlow;
  font-weight: bold;
  color: white;
  font-size: 44px;
  padding-top: 5vh;
`;

const Status = styled.div`
  font-family: Barlow;
  color: white;
  font-weight: normal;
  font-size: 34px;
  padding-top: 2vh;
`;

const ScrollDownContainer = styled.div`
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  justify-content: center;
  bottom: 50px;
`;

const Pulse = keyframes`
    0% {
		transform: scale(0.95);
	}
    
    50% {
		transform: scale(1.15);
	}
	
	100% {
		transform: scale(0.95);
	}
`;

const ScrollDownIcon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 50px;
  width: 50px;
  transform: scale(1);
  animation: ${Pulse} 2s infinite;
`;

const Header = () => {
  return (
    <div id="accueil">
      <GlobalStyle></GlobalStyle>
      <BackgroundImg>
        <HeaderProfilePicture>
          <img src={headerprofilepicture} alt=""></img>
          <Name>SANTERRE SEBASTIEN</Name>
          <Status>Développeur Web</Status>
          <ScrollDownContainer>
            <ScrollDownIcon href="#apropos">
              <FontAwesomeIcon
                icon="angle-double-down"
                style={{ color: "white", width: "30px", height: "30px" }}
              ></FontAwesomeIcon>
            </ScrollDownIcon>
          </ScrollDownContainer>
        </HeaderProfilePicture>
      </BackgroundImg>
    </div>
  );
};

export default Header;
