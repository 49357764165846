import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import GlobalFonts from "../fonts/fonts";

const MainHobbiesContainer = styled.div`
  margin: auto;
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
`;

const TestCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  place-items: center;
  align-items: center;
  padding: 30px 0 0 0;
  color: #333333;
  font-size: 40px;
  text-align: center;
`;

const HobbiesContainer = styled.div`
  padding: 30px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Interest = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100%;
  margin: 0 20px 0 0px;
  font-family: "Mark Pro" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: 768px) {
    flex-basis: 40%;
  }

  @media (min-width: 1024px) {
    flex-basis: 23%;
  }

  &:last-child {
    margin: 0;
  }

  & > h2 {
    color: #333333;
    font-weight: normal;
    padding: 15px 0 15px 0;
  }

  & > p {
    text-align: justify;
  }
`;

const Hobbies = () => {
  return (
    <div id="interets">
      <GlobalFonts />
      <TestCenter>
        <ContainerTitle>
          {/*<FontAwesomeIcon icon="user" style={{ marginRight: "15px" }} />*/}
          Centres d'intérêts
        </ContainerTitle>
      </TestCenter>
      <HobbiesContainer>
        <Interest>
          <FontAwesomeIcon
            icon="motorcycle"
            style={{ height: "100px", width: "100px", color: "#f6b93b" }}
          />
          <h2>Moto</h2>
          <p>
            J'adore voyager en moto, découvrir des paysages et profiter de
            belles routes.
          </p>
        </Interest>
        <Interest>
          <FontAwesomeIcon
            icon="running"
            style={{ height: "100px", width: "100px", color: "#f6b93b" }}
          />
          <h2>Course à pied</h2>
          <p>
            J'attache une importance particulière a ma santé alors je pratique
            régulièrement la course à pied qui fait partie d'une de mes passions
            !
          </p>
        </Interest>
        <Interest>
          <FontAwesomeIcon
            icon="hands-helping"
            style={{ height: "100px", width: "100px", color: "#f6b93b" }}
          />
          <h2>Associatif</h2>
          <p>
            Faisant parti d'une fraternité (Sigma Thêta Pi), j'y suis responsable IT et philanthropique au sein du chapitre de Lille. Je m'y investis afin de véhiculer les valeurs qui la représente à savoir : La philanthropie, l'excellence sportive et académique, être gentleman.
          </p>
        </Interest>
        <Interest>
          <FontAwesomeIcon
            icon="gamepad"
            style={{ height: "100px", width: "100px", color: "#f6b93b" }}
          />
          <h2>Jeux-vidéos</h2>
          <p>
            J'aime le fait de pouvoir développer des compétences en profitant d'un jeu vidéo, elles peuvent être les suivantes : la communication, le leadership et l'organisation.
          </p>
        </Interest>
      </HobbiesContainer>
    </div>
  );
};

export default Hobbies;
