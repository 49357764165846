import React from "react";
import styled, { css } from "styled-components";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  height: 72px;
  padding: 0;
  background-color: #f6b93b;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    position: fixed;
    background-color: #0d2538;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const NavBarAction = styled.li`
  height: 80px;
  width: 100%;
  list-style-type: none;
  color: black;

  ${({ open }) =>
    open
      ? css`
          &:hover {
            color: #3c6382;
            font-weight: bold;
          }
        `
      : css`
          &:hover {
            color: #3c6382;
            font-weight: bold;
          }
        `};

  & > a {
    height: 100%;
    width: 100%;
    font-family: Barlow;
    text-transform: uppercase;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      color: #3c6382;
    }

    ${({ open }) =>
      open
        ? css`
            &.current {
              color: #3c6382;
              font-weight: bold;
            }
          `
        : css`
            &.current {
              color: #3c6382;
              font-weight: bold;
            }
          `};
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <NavBarAction open={open}>
        <a href="#accueil">Accueil</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#apropos">à propos</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#competences">Compétences</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#experience">Expérience</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#diplomes">Diplômes</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#interets">Centres d'intérêts</a>
      </NavBarAction>
      <NavBarAction open={open}>
        <a href="#contact">Contact</a>
      </NavBarAction>
    </Ul>
  );
};

export default RightNav;
