import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import GlobalFonts from "../fonts/fonts";
import cv from "../assets/cv_sebastien_santerre.pdf";

const GlobalStyle = createGlobalStyle`
    html {
        
    }

    body {
        
    }
`;

const FooterContainer = styled.div`
  background-color: #5cbbd0;
  margin: 30px 0 0 0;
  width: 100%;
  padding: 0 0 30px 0;
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 30px 0 30px 0;
  color: #333333;
  font-size: 40px;
`;

const Text = styled.p`
  font-family: "Mark Pro" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  display: grid;
  align-items: center;
  text-align: center;
  place-items: center;
  justify-content: center;
  color: #f6b93b;
`;

const ContactBox = styled.a`
  padding: 1rem;
  margin: 0.2rem;
  border: solid 2px #f6b93b;
  border-radius: 1px;
  font-family: "Mark Pro";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0);
  color: #f6b93b;
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  text-decoration: none;
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: 768px) {
    flex-basis: 40%;
  }

  @media (min-width: 1024px) {
    flex-basis: 14%;
  }
`;

const Footer = () => {
  return (
    <div id="contact">
      <GlobalFonts />
      <GlobalStyle></GlobalStyle>
      <FooterContainer>
        <ContainerTitle>
          <FontAwesomeIcon icon="phone" style={{ marginRight: "15px" }} />
          Contact
        </ContainerTitle>
        <ContactContainer>
          <ContactBox href="https://linkedin.com/in/sebastiensanterre">
            <FontAwesomeIcon
              icon={["fab", "linkedin"]}
              style={{
                marginRight: "5px",
                height: "5rem",
                width: "5rem",
                paddingBottom: "5px",
              }}
            />
            <Text>ACCÉDER MON LINKEDIN</Text>
          </ContactBox>

          <ContactBox href={cv}>
            <FontAwesomeIcon
              icon="file-alt"
              style={{
                marginRight: "5px",
                height: "5rem",
                width: "5rem",
                paddingBottom: "5px",
              }}
            />
            <Text>TÉLÉCHARGER MON CV</Text>
          </ContactBox>

          <ContactBox href="tel:+33616507344">
            <FontAwesomeIcon
              icon="phone"
              style={{
                marginRight: "5px",
                height: "5rem",
                width: "5rem",
                paddingBottom: "5px",
              }}
            />
            06.16.50.73.44
          </ContactBox>

          <ContactBox href="mailto:bonjour@sebastiensanterre.fr">
            <FontAwesomeIcon
              icon="envelope"
              style={{
                marginRight: "5px",
                height: "5rem",
                width: "5rem",
                paddingBottom: "5px",
              }}
            />
            <Text>bonjour@sebastiensanterre.fr</Text>
          </ContactBox>
          <ContactBox href="https://projets.sebastiensanterre.fr">
            <FontAwesomeIcon
              icon="globe"
              style={{
                marginRight: "5px",
                height: "5rem",
                width: "5rem",
                paddingBottom: "5px",
              }}
            />
            <Text>ACCÉDER À MON PORTFOLIO</Text>
          </ContactBox>
        </ContactContainer>
      </FooterContainer>
    </div>
  );
};

export default Footer;
