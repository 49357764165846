import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faCode,
  faRunning,
  faMotorcycle,
  faGamepad,
  faHandsHelping,
  faAngleDoubleDown,
  faCalendarDay,
  faUser,
  faUserGraduate,
  faCogs,
  faPhone,
  faMailBulk,
  faBars,
  faFileAlt,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faCode,
  faRunning,
  faMotorcycle,
  faHandsHelping,
  faGamepad,
  faAngleDoubleDown,
  faCalendarDay,
  faUser,
  faUserGraduate,
  faCogs,
  faPhone,
  faMailBulk,
  faBars,
  faFileAlt,
  faEnvelope,
  faGlobe
);
