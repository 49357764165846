import React, { useEffect } from "react";
import styled from "styled-components";
import Burger from "./Burger";
import * as _ from "underscore";

const Nav = styled.nav`
  width: 100%;
  height: 70px;
  background-color: #f6b93b;
  display: flex;
  justify-content: space-between;
`;

const Navbar = () => {
  const onScroll = () => {
    let mainNavLinks = document.querySelectorAll("nav ul li a");
    let fromTop = window.scrollY;

    mainNavLinks.forEach((link) => {
      let section = document.querySelector(link.hash);
      if (
        section.offsetTop <= fromTop + 80 &&
        section.offsetTop + section.offsetHeight > fromTop + 80
      ) {
        link.classList.add("current");
      } else {
        link.classList.remove("current");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(onScroll, 100));
    return () =>
      window.removeEventListener("scroll", _.throttle(onScroll, 100));
  }, []);
  return (
    <Nav>
      <Burger />
    </Nav>
  );
};

export default Navbar;
