import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import "../../node_modules/aos/dist/aos.css";

const DiplomasContainer = styled.div`
  background-color: #5cbbd0;
  margin: 30px 0 0 0;
  width: 100%;
  padding: 0 0 30px 0;
  position: relative;
  z-index: -2;
  display: grid;
  place-items: center;

  &::before {
    content: "";
    width: 4px;
    background: #374d7c;
    height: 70%;
    top: 20%;
    margin-left: -3px;
    position: absolute;
  }
`;

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 30px 0 0 0;
  color: #f2f2f2;
  font-size: 40px;
`;

const Item = styled.div`
  position: relative;
  width: 350px;
  height: auto;
  margin: 50px 0 0 0;
  padding: 20px;
  left: 60%;
  background: #374d7c;
  color: #fff;
  border-radius: 1px;

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right: 8px solid #374d7c;
    top: 15px;
  }

  &:nth-child(4)::before {
    left: 100%;
    border: 8px solid transparent;
    border-left: 8px solid #374d7c;
  }

  &:nth-child(4) {
    left: 0;
  }

  @media (max-width: 1120px) {
    display: none;
  }
`;

const ResponsiveItem = styled.div`
  position: relative;
  width: 80%;
  height: auto;
  margin: 50px auto 0 auto;
  padding: 20px;
  background: #374d7c;
  color: #fff;
  border-radius: 1px;

  @media (min-width: 1120px) {
    display: none;
  }
`;

const Dot = styled.div`
  content: "";
  position: relative;
  width: 2rem;
  height: 2rem;
  background: #374d7c;
  border-radius: 50%;
  margin: auto;
  top: 90px;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  @media (max-width: 1120px) {
    display: none;
  }
`;

const Chronologie = styled.div`
  width: 90%;
  max-width: 1000px;
`;

const Description = styled.p`
  font-family: "Mark Pro" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  text-align: justify;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 5px 0;
  text-transform: uppercase;
`;

const DateInterval = styled.h2`
  font-weight: normal;
  font-size: 16px;
`;

const Diplomas = () => {
  return (
    <div style={{ paddingTop: "20px" }} id="diplomes">
      <DiplomasContainer>
        <ContainerTitle>
          <FontAwesomeIcon
            icon="user-graduate"
            style={{ marginRight: "15px" }}
          />
          Diplômes
        </ContainerTitle>
        <Chronologie>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-left">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2019 - Juillet 2020
            </DateInterval>
            <Title>LP RGI</Title>
            <Description>
              IUT d'Amiens. Obtention de la licence professionnelle Réseau et
              Génie informatique spécialité Web.
            </Description>
          </Item>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-right">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2016 - Juillet 2018
            </DateInterval>
            <Title>DUT Informatique</Title>
            <Description>
              IUT d'Amiens. Obtention du DUT Informatique.
            </Description>
          </Item>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-left">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Juillet 2015
            </DateInterval>
            <Title>BAC S - Sciences de l'ingénieur</Title>
            <Description>
              Lycée Michel Anguier de la ville d'Eu. Bac Scientifique option
              Sciences de l'ingénieur, spécialité Informatique et sciences du
              numérique, avec mention assez bien.
            </Description>
          </Item>

          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2019 - Juillet 2020
            </DateInterval>
            <Title>LP RGI</Title>
            <Description>
              IUT d'Amiens. Obtention de la licence professionnelle Réseau et
              Génie informatique spécialité Web.
            </Description>
          </ResponsiveItem>
          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2016 - Juillet 2018
            </DateInterval>
            <Title>DUT Informatique</Title>
            <Description>
              IUT d'Amiens. Obtention du DUT Informatique.
            </Description>
          </ResponsiveItem>
          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Juillet 2015
            </DateInterval>
            <Title>BAC S - Sciences de l'ingénieur</Title>
            <Description>
              Lycée Michel Anguier de la ville d'Eu. Bac Scientifique option
              Sciences de l'ingénieur, spécialité Informatique et sciences du
              numérique, avec mention assez bien.
            </Description>
          </ResponsiveItem>
        </Chronologie>
      </DiplomasContainer>
    </div>
  );
};

export default Diplomas;
