import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const SkillsContainer = styled.div`
  background-color: #5cbbd0;
  margin: 30px 0 0 0;
  width: 100%;
  padding: 0 0 40px 0;
`;

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 30px 0 0 0;
  color: #f2f2f2;
  font-size: 40px;
`;

const ContainerSubTitle = styled.h2`
  font-family: Barlow;
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 20px 0 0 0;
  color: #f2f2f2;
  font-size: 30px;
  font-weight: normal;
`;

const SubFillingBarContainer = styled.div`
  padding: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
    padding: 0;
  }
`;

const FillingBarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-family: Barlow;
  color: white;
  font-weight: bold;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    place-items: center;
  }
`;

const Skill = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const FillingBar = styled.div`
  background-color: white;
  border-radius: 5px;
  height: 20px;
  width: 30vw;
  margin: 15px 0 11px 5px;
  right: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Filling = styled.div`
  background-color: ${(props) => props.bgcolor};
  border-radius: 5px;
  height: 20px;
  width: ${(props) => props.width};
`;

const CircularBarContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0 0 0;
  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: center;
    place-items: center;
  }
`;

const SkillText = styled.p`
  width: 120px;
`;

/*const FillingBar = styled.div `
    background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 500px;
`;

const load = keyframes`
    0% { width: 0; }
  100% { width: 80%; }
`;

const Filling = styled.div`
    animation: ${load} 1s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 10px;
    background: #fff;
    height: 30px;
    width: 0;
    
`;*/

const Skills = () => {
  return (
    <div style={{ paddingTop: "50px" }} id="competences">
      <SkillsContainer>
        <ContainerTitle>
          <FontAwesomeIcon icon="code" style={{ marginRight: "15px" }} />
          Compétences
        </ContainerTitle>
        <FillingBarContainer>
          <SubFillingBarContainer>
            <Skill>
              <SkillText>HTML/CSS</SkillText>
              <FillingBar>
                <Filling width="80%" bgcolor="#f6b93b"></Filling>
              </FillingBar>
            </Skill>
            <Skill>
              <SkillText>ReactJS/VueJS</SkillText>
              <FillingBar>
                <Filling width="70%" bgcolor="#e17055"></Filling>
              </FillingBar>
            </Skill>
            <Skill>
              <SkillText>NodeJS/Ruby</SkillText>

              <FillingBar>
                <Filling width="60%" bgcolor="#f6b93b"></Filling>
              </FillingBar>
            </Skill>
          </SubFillingBarContainer>
          <SubFillingBarContainer>
            <Skill>
              <SkillText>JavaScript</SkillText>

              <FillingBar>
                <Filling width="70%" bgcolor="#e17055"></Filling>
              </FillingBar>
            </Skill>
            <Skill>
              <SkillText>MySQL/PSQL</SkillText>
              <FillingBar>
                <Filling width="80%" bgcolor="#f6b93b"></Filling>
              </FillingBar>
            </Skill>
            <Skill>
              <SkillText>Déploiement</SkillText>
              <FillingBar>
                <Filling width="60%" bgcolor="#e17055"></Filling>
              </FillingBar>
            </Skill>
          </SubFillingBarContainer>
        </FillingBarContainer>

        <ContainerSubTitle>Plus de compétences</ContainerSubTitle>

        <CircularBarContainer>
          <CircularProgressbar
            value={80}
            text={`Anglais`}
            styles={{
              root: { width: "150px", padding: "20px" },
              path: {
                stroke: `#e17055`,
                strokeLinecap: "butt",
              },
              text: {
                fill: "white",
                fontSize: "16px",
                fontFamily: "Barlow",
              },
            }}
          ></CircularProgressbar>

          <CircularProgressbar
            value={50}
            text={`Java/PHP`}
            styles={{
              root: { width: "150px", padding: "20px" },
              path: {
                stroke: `#f6b93b`,
                strokeLinecap: "butt",
              },
              text: {
                fill: "white",
                fontSize: "16px",
                fontFamily: "Barlow",
              },
            }}
          ></CircularProgressbar>

          <CircularProgressbar
            value={80}
            text={`Agile`}
            styles={{
              root: { width: "150px", padding: "20px" },
              path: {
                stroke: `#e17055`,
                strokeLinecap: "butt",
              },
              text: {
                fill: "white",
                fontSize: "16px",
                fontFamily: "Barlow",
              },
            }}
          ></CircularProgressbar>
        </CircularBarContainer>
      </SkillsContainer>
    </div>
  );
};

export default Skills;
