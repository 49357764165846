import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";
import GlobalFonts from "../fonts/fonts";

const ExperienceContainer = styled.div`
  margin: 0;
  width: 100%;
  padding: 0 0 30px 0;
  position: relative;
  z-index: -2;
  display: grid;
  place-items: center;

  &::before {
    content: "";
    width: 4px;
    background: #5cbbd0;
    height: 74%;
    top: 12%;
    margin-left: -2px;
    position: absolute;
  }
`;

const ContainerTitle = styled.h1`
  font-family: Barlow;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  place-items: center;
  padding: 30px 0 0 0;
  color: #333333;
  font-size: 40px;
`;

const Item = styled.div`
  position: relative;
  width: 350px;
  height: auto;
  margin: 50px 0 0 0;
  padding: 20px;
  left: 60%;
  background: #5cbbd0;
  color: #fff;
  border-radius: 1px;

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right: 8px solid #5cbbd0;
    top: 15px;
  }

  &:nth-child(4n + 2)::before {
    left: 100%;
    border: 8px solid transparent;
    border-left: 8px solid #5cbbd0;
  }

  &:nth-child(4n + 2) {
    left: 0;
  }

  @media (max-width: 1120px) {
    display: none;
  }
`;

const ResponsiveItem = styled.div`
  position: relative;
  width: 80%;
  height: auto;
  margin: 50px auto 0 auto;
  padding: 20px;
  background: #5cbbd0;
  color: #fff;
  border-radius: 1px;

  @media (min-width: 1120px) {
    display: none;
  }
`;

const Dot = styled.div`
  content: "";
  position: relative;
  width: 2rem;
  height: 2rem;
  background: #5cbbd0;
  border-radius: 50%;
  margin: auto;
  top: 90px;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  @media (max-width: 1120px) {
    display: none;
  }
`;

const Chronologie = styled.div`
  width: 90%;
  max-width: 1000px;
`;

const Description = styled.p`
  font-family: "Mark Pro" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  text-align: justify;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 5px 0;
  text-transform: uppercase;
`;

const DateInterval = styled.h2`
  font-weight: normal;
  font-size: 16px;
`;

const Experience = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
    AOS.refresh();
  }, []);

  return (
    <div id="experience">
      <GlobalFonts />
      <ExperienceContainer>
        <ContainerTitle>
          <FontAwesomeIcon icon="cogs" style={{ marginRight: "15px" }} />
          Expérience
        </ContainerTitle>
        <Chronologie>
        <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-left">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Décembre 2020 - Octobre 2021
            </DateInterval>
            <Title>Développeur full-stack chez Xeno</Title>
            <Description>
              Start-up située à Lille(Euratechnologies). 
              J'ai travaillé sous VueJS/Ruby on Rails dans le cadre d'implémentation de nouvelles fonctionnalitées ainsi que la maintenance/support sur une application existante.
              J'étais moi même responsable de ma propre feature au cours de mon contrat au sein de l'entreprise Xeno.
              J'ai aussi pu utiliser la méthode Agile pour mener à bien le développement de ma feature et la maintenance des autres.
            </Description>
          </Item>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-right">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2019 - Juillet 2020
            </DateInterval>
            <Title>Alternance à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. J'ai travaillé sous
              ReactJS, NextJS, Express et la librairie Styled-Components dans le
              cadre de la maintenance et de l'évolution d'une PWA se chargeant
              de centraliser la communication entre tous les clients et
              l'agence. Aussi j'ai été chargé du déploiement de cette
              application.
              <br /> J'ai également pu créer la même application sous la
              plateforme de développement Bubble.
            </Description>
          </Item>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-left">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Avril 2019 - Juin 2019
            </DateInterval>
            <Title>Stage à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. Maintenance et évolution
              d'une PWA sous ReactJS, NextJS, Express et la librairie
              Styled-Components.
            </Description>
          </Item>
          <Dot data-aos="zoom-in"></Dot>
          <Item data-aos="fade-right">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Avril 2018 - Juin 2018
            </DateInterval>
            <Title>Stage à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. J'ai été chargé de
              développer une PWA sous ReactJS, NextJS et Express. Cette
              application se charge de centraliser la communication entre tous
              les clients et l'agence elle même.
            </Description>
          </Item>

          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Septembre 2019 - Juillet 2020
            </DateInterval>
            <Title>Alternance à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. J'ai travaillé sous
              ReactJS, NextJS, Express et la librairie Styled-Components dans le
              cadre de la maintenance et de l'évolution d'une PWA se chargeant
              de centraliser la communication entre tous les clients et
              l'agence. Aussi j'ai été chargé du déploiement de cette
              application.
              <br /> J'ai également pu créer la même application sous la
              plateforme de développement Bubble.
            </Description>
          </ResponsiveItem>
          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Avril 2019 - Juin 2019
            </DateInterval>
            <Title>Stage à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. Maintenance et évolution
              d'une PWA sous ReactJS, NextJS, Express et la librairie
              Styled-Components.
            </Description>
          </ResponsiveItem>
          <ResponsiveItem data-aos="zoom-in">
            <DateInterval>
              <FontAwesomeIcon
                icon="calendar-day"
                style={{ marginRight: "5px" }}
              />
              Avril 2018 - Juin 2018
            </DateInterval>
            <Title>Stage à l'agence YOUCOM</Title>
            <Description>
              Agence de communication située à Amiens. J'ai été chargé de
              développer une PWA sous ReactJS, NextJS et Express. Cette
              application se charge de centraliser la communication entre tous
              les clients et l'agence elle même.
            </Description>
          </ResponsiveItem>
        </Chronologie>
      </ExperienceContainer>
    </div>
  );
};

export default Experience;
