import { createGlobalStyle } from "styled-components";

import eot1 from "./7200c6dd8ac604abe09f5159e53a40c0.eot";
import eot2 from "./7200c6dd8ac604abe09f5159e53a40c0.eot?#iefix";
import woff2 from "./7200c6dd8ac604abe09f5159e53a40c0.woff2";
import woff from "./7200c6dd8ac604abe09f5159e53a40c0.woff";
import truetype from "./7200c6dd8ac604abe09f5159e53a40c0.ttf";
import svg from "./7200c6dd8ac604abe09f5159e53a40c0.svg";

export default createGlobalStyle`
    @font-face {font-family: "Mark Pro";
        src: url(${eot1}); /* IE9*/
        src: url(${eot2}) format("embedded-opentype"), /* IE6-IE8 */
        url(${woff2}) format("woff2"), /* chrome、firefox */
        url(${woff}) format("woff"), /* chrome、firefox */
        url(${truetype}) format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url(${svg}) format("svg"); /* iOS 4.1- */
    }
`;
